
<!-- <script type="text/javascript" src="//api.map.baidu.com/api?type=webgl&v=1.0&ak=3jFqlYOIlcRIUc3YoCVdziNtMOFXcFn5"></script> -->
<template>
    <div>
        <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/contact-us.jpg')})` }">
        </div>
    </div>
    <div>
        <div>

            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
                @select="handleSelect" :router="true">
                <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
                <el-menu-item disabled="true"></el-menu-item>
                <el-menu-item index="/contact" class="menu1">联系我们</el-menu-item>
                <el-menu-item index="/Massage" class="menu">在线留言</el-menu-item>

            </el-menu>
            <div class="Breadcrumbbround">
                <div class="Breadcrumb">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

            </div>


            <div class="scd_r">
                <!-- <div class="r_title">
              企业文化<span>/<em>Corporate Culture</em></span>
            </div> -->
                <div class="tagline">
                    <el-row>
                        <el-col :span="24">
                            <div class="biaoti">
                                <p>云南昆船机械制造有限公司</p>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="height: 30px;">&nbsp;</el-row>
                    <el-row>
                        <el-icon style="color: #00428d;font-size: 30px;">
                            <Location />
                        </el-icon>
                        <p  style="margin-left: 10px;">公司地址：云南自由贸易试验区昆明经济技术开发区昆船工业园内</p>
                    </el-row>
                    <el-row style="height: 30px;">&nbsp;</el-row>
                    <el-row>
                        <el-icon style="color: #00428d;font-size: 30px;">
                            <Phone />
                        </el-icon>
                        <p  style="margin-left: 10px;">联系方式：</p>
                        <p>
                            0871-67232089（电话/传真）

                        </p>
                    </el-row>
                    <el-row>
                        <p style="text-indent: 120px;">13508800837(冯先生)、15969564940(于先生)</p>
                    </el-row>
                    <el-row>
                        <p style="text-indent: 120px;">15808752812(王先生)</p>
                    </el-row>
                    <el-row>
                        <p style="margin-left: 40px;">公司微信公众号：</p>
                    </el-row>
                    <el-row>
                        <el-image style="width: 12%;margin-left: 8%;margin-top: 15px;" :src="require('../assets/images/code.jpg')" :zoom-rate="1.2"
                            :max-scale="7" :min-scale="0.2" :preview-src-list="srcList2" :initial-index="4"
                            fit="cover"  />
                    </el-row>



                    <el-row style="height: 60px;">&nbsp;</el-row>




                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Location,Phone,HomeFilled,ArrowRight  } from '@element-plus/icons-vue'
import {useRoute} from 'vue-router'
const route = useRoute()

const handleClick = ()=>{
    window.location = "http://localhost:8080"
}
const srcList2 = [
  require('../assets/images/code.jpg'),
]
</script>
<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
}
.menu{
  width: 190px;
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 35%;
}
.news-header {
    width: 100%;
    height: 300px;
    background-size: cover;
}
//字体
* {
  font-family: "OPPO Sans";
  }
  //
.company-pic{   
    width: 100%;
    padding-left: 40%;
}
.scd .scd_r {
    width: 945px;
    float: right;
}

.scd_r .r_title {
    width: 100%;
    text-align: center;
    padding-top: 55px;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #ccc;
    font-size: 30px;
    color: #000000;
}

.scd_r .r_title span {
    color: #999999;
}

.scd_r .r_title span em {
    font-size: 18px;
}

.scd_r .scd_m {
    width: 100%;
    padding: 28px 0;
    font-size: 14px;
    color: #999999;
    line-height: 215%;
}

.scd {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 20px;
}
.scd .scd_l{
	width: 220px;
	height: 700px;
	float: left;
	background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name{ width:100%; text-align:center; padding:30px 0;}
.scd_l .s_nav{
	width: 100%;
	text-align: left;
}
.scd_l .s_nav li{
	width: 190px;
	height: 42px;
	background: url(../assets/picture/line1.jpg) no-repeat left bottom;
	float: right;
	margin-right: 1px;
}
.scd_l .s_nav li a{
	display: block;
	width: 190px;
	height: 40px;
	padding-right: 0px;
	text-align: left;
	line-height: 40px;
	font-size: 16px;
	color: #552F00;
}
.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
    background: #00428d;
    color: #FFF;
}

.scd_l .s_nav li a span {
    display: inline-block;
    *display: inline;
    zoom: 1;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
    color: #FFF;
    background: url(../assets/picture/icon2.png) no-repeat left center;
}
.p{
    text-indent: 2em;
}
.biaoti{
    padding-top: 20px;
    color: #00428d;
    font-size: x-large;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
//
.tagline{
    margin-left: 12%;
}
</style>