<template>
    <div>
        <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/Recruitment.jpg')})` }">
        </div>
        <div>
            <div>
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
                    @select="handleSelect" :router="true">
                    <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
                    <el-menu-item disabled="true"></el-menu-item>
                    <el-menu-item index="/recruitment" class="menu">招聘公告</el-menu-item>
                    <el-menu-item index="/recruitmentschool" class="menu1">校园招聘</el-menu-item>
                    <el-menu-item index="/recruitmentsocial" class="menu">社会招聘</el-menu-item>

                </el-menu>
                <div class="Breadcrumbbround">
                    <div class="Breadcrumb">
                        <el-breadcrumb :separator-icon="ArrowRight">
                            <el-breadcrumb-item :to="{ path: '/' }">
                                <el-icon style="color: #00428d;font-size: larger;">
                                    <HomeFilled />
                                </el-icon>
                            </el-breadcrumb-item>
                            <el-breadcrumb-item>诚聘英才</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ currentNews.post }}</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>

                </div>

                <div class="scd_r">
                    <!-- <div class="r_title">
            公司简介<span>/<em>Company profile</em></span>
          </div> -->
                    <div class="scd_m">
                        <el-card class="recruitmentinformation">
                            <div>
                                <h2>
                                    {{ currentNews.post }}

                                </h2>
                                <p style="font-size: 13px;padding-top: 20px;color: red;">
                                    {{ currentNews.salary }}
                                </p>
                                <div class="yaoqiu">
                                    <p>招聘人数 : {{ currentNews.number }}</p>
                                    <p>岗位要求:{{ currentNews.educational }}</p>
                                    <p>工作地点:{{ currentNews.location }}</p>
                                    <!-- <a>社会招聘</a>
                                    <a>|</a>
                                    <a>经验不限</a>
                                    <a>|</a> -->
                                    <!-- <a>|</a>
                                    <a>全职</a> -->
                                </div>
                            </div>


                            <div class="recruitment">
                                <h4>专业要求</h4>
                                <p>
                                    {{ currentNews.profession }}
                                </p>
                                <h4>岗位主要工作内容</h4>
                                <p>
                                    {{ currentNews.content }}
                                </p>
                                <h4>薪资福利</h4>
                                <p>（一）薪酬</p>
                                <p>
                                    云南昆船机械制造有限公司确立以价值创造者为本的指导思想，积极建立与现代企业制度相适应的薪酬制度，薪酬待遇包含基本工资、各项补贴（全勤奖、知识分子补贴、工龄补贴、交通补贴等）、过节费、福利费、绩效收入及缴纳五险两金等，对新进大学生给予较高薪酬待遇水平。
                                </p>
                                <p>
                                    新参加工作两年内，根据学历层次，给予较为稳定的年度薪酬收入水平为：博士研究生12万～15万，硕士研究生8万～12万，本科毕业生6万～10万。两年期满后，根据人事岗位、业务能力等综合评价核定。
                                </p>
                                <p>
                                    （二）福利
                                </p>
                                <p>
                                    1. 按规定缴纳五险两金；
                                </p>
                                <p>
                                    2. 双休、国家法定节假日、带薪年休假、婚嫁、产假、探亲假；
                                </p>
                                <p>
                                    3. 内外培训、交通补贴、健康体检等。
                                </p>
                                <p>
                                    4.安家费：985院校：博士30万、硕士20万、本科15万；
                                </p>
                                <p style="padding-left: 80px;">
                                    211院校：博士20万、硕士15万、本科10万。
                                </p>
                                <h4>发展晋升</h4>
                                <p>
                                    1.公司非常重视人才，一直坚持“以人为本”的用人理念，用人机制完善，发展晋升通道畅通。
                                </p>
                                <p>
                                    2.公司三支人才队伍均设置有初职、中职、高职、公司级带头人、公司级首席专家、昆船级带头人、昆船级首席专家、中船级带头人、中船级首席专家的晋升通道。
                                </p>
                                <h4>招聘流程</h4>
                                <p>
                                    简历收集及筛选→面试→签约
                                </p>
                                <p>
                                    个人简历:包括本人基本情况、教育背景、学业成绩、实习或工作经历等情况。
                                </p>
                                <p>
                                    各类证书原件及复印件。
                                </p>
                                <h4>联系方式</h4>
                                <p>
                                    电话：15912456175（盛老师）、15974849356（杨老师）
                                </p>
                                <p>
                                    邮箱:kcjx_hr1@163.com (发送“姓名+学历+专业+应聘岗位”及简历)
                                </p>
                                <p>
                                    公司地址：云南省昆明市国家经济技术开发区昆船工业区
                                </p>



                                <p>&nbsp;</p>
                                <!--<table width="160" border="0">
                <tr>
                  <img src="Assets/images/zz.jpg" width="150"  >
                  </tr>
                </table>
              <p>&nbsp;</p>
              <p><img src="Assets/images/企业架构图.png" width="1000" ></p>-->
                            </div>

                        </el-card>

                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script setup>
//watchEffect里面依赖的的值变化了钩子重新执行
import { onMounted, ref,watchEffect,onBeforeUnmount } from "vue";
import axios from "axios";
import { useRoute,useRouter } from "vue-router";
import moment from "moment";
import { StarFilled ,HomeFilled,ArrowRight} from "@element-plus/icons-vue";


moment.locale("zh-cn");
const route = useRoute();
const router = useRouter();
const currentNews = ref({});

const stop  = watchEffect(async () => {
//   console.log(111,route.params.id)
  if(!route.params.id) return 
  const res1 = await axios.get(`/webapi/post/listschool/${route.params.id}`);
 
  //   console.log(res2.data.data)
  currentNews.value = res1.data.data[0];
});
  
onBeforeUnmount(()=>{
    console.log(222,"onBeforeUnmount")
    stop()
})
const whichTime = time => {
  return moment(time).format("lll");
};
const handleChange = (id)=>{
    // console.log(id)
    router.push(`/recruitmentschool/${id}`)
}
</script>
<style scoped lang="scss">
.news-header {
  width: 100%;
  height: 300px;
  background-size: cover;
}

* {
  font-family: "OPPO Sans";
}

//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
}
.menu{
  width: 190px;
  
  
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 30%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
.el-row{
    margin-top: 40px;
}
.time {
  font-size: 13px;
  color: gray;
}
.toptitle{
    text-align: center;
}
.newsdetails::v-deep {
    font-size:18px;
     text-indent:35px; 
     line-height:35px;

    img {
        width: 600px;
        display: block;
        margin: 0 auto;
    }

}
.author{
    float: right;
    padding-top: 15px;
    padding-right: 10%;
}
.blank{
    height: 100px;
}
.recruitmentinformation{
    width: 75%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 60px;
}
.recruitment h4{
  color: #00428d;
  padding-top: 20px;
}
.yaoqiu {

	
	padding-top: 2%;
}
.yaoqiu p {
    font-size: 15px;
    color: #001e3f;
    font-weight: 600;
}
</style>