import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import News from '../views/News.vue'
import Capabilitys from '../views/Capabilitys.vue'
import New from '../views/New.vue'
import Capability from '../views/Capability.vue'
import Companyprofile from '../views/Company profile.vue'
import Corporateculture from '../views/Corporate culture.vue'
import Qualification from '../views/Qualification.vue'
import Honors from '../views/Honors.vue'
import NProgress from 'nprogress'
import Videos from '../views/Videos.vue'
import Video1 from '../views/Video1.vue'
import Video2 from '../views/Video2.vue'
import Video3 from '../views/Video3.vue'
import Video4 from '../views/Video4.vue'
import Video5 from '../views/Video5.vue'
import Video6 from '../views/Video6.vue'
import Video7 from '../views/Video7.vue'
import Contact from '../views/Contact.vue'
import Massage from '../views/Massage.vue'
import Recruitment from '../views/Recruitment.vue'
import Recruitmentschool from '../views/Recruitmentschool.vue'
import Recruitmentschooldetail from '../views/Recruitmentschooldetail.vue'
import Recruitmentsocial from '../views/Recruitmentsocial.vue'
import Recruitmentsocialdetail from '../views/Recruitmentsocialdetail.vue'
import Products from '../views/Products.vue'
import 'nprogress/nprogress.css'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:id',
    name: 'new',
    component: New
  },
  {
    path: '/companyprofile',
    name: 'companyprofile',
    component: Companyprofile
  },
  {
    path: '/corporateculture',
    name: 'corporateculture',
    component: Corporateculture
  },
  {
    path: '/qualification',
    name: 'qualification',
    component: Qualification
  },
  {
    path: '/honors',
    name: 'honors',
    component: Honors
  },
  {
    path: '/capabilitys',
    name: 'capabilitys',
    component: Capabilitys
  },
  {
    path: '/capabilitys/:id',
    name: 'capability',
    component: Capability
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos
  },
  {
    path: '/video1',
    name: 'video1',
    component: Video1
  },
  {
    path: '/video2',
    name: 'video2',
    component: Video2
  },
  {
    path: '/video3',
    name: 'video3',
    component: Video3
  },
  {
    path: '/video4',
    name: 'video4',
    component: Video4
  },
  {
    path: '/video5',
    name: 'video5',
    component: Video5
  },
  {
    path: '/video6',
    name: 'video6',
    component: Video6
  },
  {
    path: '/video7',
    name: 'video7',
    component: Video7
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/massage',
    name: 'massage',
    component: Massage
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: Recruitment
  },
  {
    path: '/recruitmentschool',
    name: 'recruitmentschool',
    component: Recruitmentschool
  },
  {
    path: '/recruitmentschool/:id',
    name: 'recruitmentschooldetail',
    component: Recruitmentschooldetail
  },
  {
    path: '/recruitmentsocial',
    name: 'recruitmentsocial',
    component: Recruitmentsocial
  },
  {
    path: '/recruitmentsocial/:id',
    name: 'recruitmentsocialdetail',
    component: Recruitmentsocialdetail
  },
  {
    path: '/Products',
    name: 'products',
    component: Products
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to,from,next)=>{
  NProgress.start();
  next()
})

router.afterEach((to,from,next)=>{
  NProgress.done();
  //next()
})

export default router
