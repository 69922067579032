<template>
  <div class="container">
    <div class="banner">
      <el-carousel class="bannerpic" height="calc(100vh - 90px)" :interval="4000">
        <el-carousel-item :key="1">
          <div :style="{backgroundImage:`url(${require('@/assets/banner1.jpg')})`}"></div>
        </el-carousel-item>
        <el-carousel-item :key="2">
          <div :style="{backgroundImage:`url(${require('@/assets/banner2.jpg')})`}"></div>
        </el-carousel-item>
        <el-carousel-item :key="3">
          <div :style="{backgroundImage:`url(${require('@/assets/banner3.jpg')})`}"></div>
        </el-carousel-item>
        <el-carousel-item :key="4">
          <div :style="{backgroundImage:`url(${require('@/assets/banner4.jpg')})`}"></div>
        </el-carousel-item>
        <!-- <el-carousel-item :key="5">
          <div :style="{backgroundImage:`url(${require('@/assets/banner5.jpg')})`}"></div>
        </el-carousel-item> -->
      </el-carousel>
    </div>
    <!-- 最新动态 -->
    <div class="news">
      <div class="newstitle">
        <h3>最新动态</h3>
      </div>
      <el-row>
        <el-col :span="24">
          <div :style="{
          backgroundImage: `url(${require('@/assets/sanse.png')})`,
          backgroundSize: 'cover'
        }" class="sanse">&nbsp;</div>
        </el-col>
      </el-row>
      <div class="newsitem">
        <el-row>

          <el-col :span="12">
            <el-carousel class="newspics" :interval="3000" style="width:580px" height="380px">
              <el-carousel-item v-for="item in topNewsList" :key="item._id">
                <div hadow="hover" @click="handleChangepage(item._id)" :style="{
          backgroundImage: `url(http://localhost:3000${item.cover})`,
          backgroundSize: 'cover'
        }">
                  <h3>{{ item.title }}</h3>
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <!-- <el-col :span="1">
          </el-col> -->
          <el-col :span="11">
            <div v-for="item in topNewsListTitle" :key="item._id" class="text item" @click="handleChangepage(item._id)">
              <span class="newsdetailtitle">{{ item.title }}</span>
              <el-text line-clamp="2" style="width: 100%;padding-top: 2px;font-size: 10px; ">
                <span>{{ item.sketch }}</span>
              </el-text>
              <div class="bottom" style="margin-top: -10px;">
                <time class="time">{{ whichTime(item.editTime) }}</time>


              </div>
              <el-divider border-style="dashed" style="margin-top: 0;" />
            </div>
          </el-col>

          <el-col :span="1">
          </el-col>
        </el-row>
        <div class="more-blue">
          <el-button @click="newsgateToTargetPage" color="#00428d" class="more">查看更多</el-button>
        </div>
      </div>

    </div>
    <!-- 制造能力 -->
    <div class="capability">
      <div class="capabilitytitle">
        <h3>制造能力</h3>
      </div>
      <el-row>
        <el-col :span="24">
          <div :style="{
          backgroundImage: `url(${require('@/assets/white.png')})`,
          backgroundSize: 'cover'
        }" class="sanse">&nbsp;</div>
        </el-col>
      </el-row>
      <el-carousel :interval="4000" type="card" height="350px" class="capabilitydetail">

        <el-carousel-item v-for="item in tablist" :key="item.name">
          <div v-for="data in tabmanufacturing[item.name]" :key="data._id" >
            <div class="manufacturing" :style="{ backgroundImage: `url(http://localhost:3000${data.cover})` }" >
            </div>
          </div>
          <!-- <div :style="{backgroundImage:`url(${require('@/assets/images/铸造生产-home.jpg')})`}"></div> -->
        </el-carousel-item>



      </el-carousel>
      <!-- <el-carousel class="bannerpic" height="calc(100vh - 90px)" :interval="4000">
        <el-carousel-item :key="1">
          <div :style="{backgroundImage:`url(${require('@/assets/banner1.jpg')})`}"></div>
        </el-carousel-item>
  <el-carousel :interval="4000" type="card" height="200px">
    <el-carousel-item v-for="item in 6" :key="item">
      <h3 text="2xl" justify="center">{{ item }}</h3>
    </el-carousel-item>
  </el-carousel>
      </el-carousel> -->
      <div class="more-white">
        <el-button @click="capabilitygateToTargetPage" color="#00428d" class="more" :dark="isDark"
          plain>查看更多</el-button>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="about">
      <div class="newstitle">
        <h3>关于我们</h3>
      </div>
      <el-row>
        <el-col :span="24">
          <div :style="{
          backgroundImage: `url(${require('@/assets/sanse.png')})`,
          backgroundSize: 'cover'
        }" class="sanse">&nbsp;</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
        </el-col>
        <el-col :span="11">
          <el-row>
            <div class="abouttext">
              <p>
                云南昆船机械制造有限公司（以下简称公司）成立于1969年，前身为云南昆船第一机械有限公司，2017年更名为云南昆船机械制造有限公司。公司位于昆明国家经济技术开发区昆船工业园，隶属于昆明船舶设备集团有限公司，是大型国有高新技术骨干制造企业、云南省高新技术企业、云南省机械工业质量协会第四届理事会理事单位。

                公司注册资本4.7亿元,现有资产近7.8亿元，生产厂房建筑面积8万多平方米，在职职工1400余人。公司是国家首批创新型企业，国家首批企事业知识产权示范单位。
              </p>
            </div>
          </el-row>
          <el-row>&nbsp;</el-row>
          <el-row>
            <div class="more-blue">
              <el-button @click="aboutgateToTargetPage" color="#00428d" class="more">查看更多</el-button>
            </div>
          </el-row>
        </el-col>
        <!-- <el-col :span="1">
        </el-col> -->
        <el-col :span="10">
          <el-row style="height: 40px;">&nbsp;</el-row>
          <el-row>
            <el-col :span="9">
              <el-card class="introduction">
                <a @click="aboutgateToTargetPage" target="_blank">
                  <img src="../assets/images/公司简介.png" class="introductionpic">
                </a>
                <h3>公司简介</h3>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="introduction2">
                <a @click="corporateculturegateToTargetPage" target="_blank">
                  <img src="../assets/images/企业文化.png" class="introductionpic">
                </a>
                <h3>企业文化</h3>
              </el-card>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-card class="introduction">
                <a @click="qualificationgateToTargetPage" target="_blank">
                  <img src="../assets/images/企业资质.png" class="introductionpic">
                </a>
                <h3>企业资质</h3>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="introduction2">
                <a @click="honorsgateToTargetPage" target="_blank">
                  <img src="../assets/images/荣誉.png" class="introductionpic">
                </a>
                <h3>公司荣誉</h3>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="1">
        </el-col>
      </el-row>
    </div>
    <!-- 从事范围 -->
    <div class="scope">
      <div class="scopetitle">
        <h3>从事范围</h3>
      </div>
      <el-row>
        <el-col :span="24">
          <div :style="{
          backgroundImage: `url(${require('@/assets/sanse.png')})`,
          backgroundSize: 'cover'
        }" class="sanse">&nbsp;</div>
        </el-col>
      </el-row>
      <el-row>&nbsp;</el-row>
      <div class="i_mc">
        <div class="i_m">
          <ul class="clearfix">
            <li>
              <div class="scopepic"><img src="../assets/images/s1.png" alt="" /></div>
              <h6>研发设计</h6>
              <p>通用设备及专用设备设计，机电领域内的技术研发，金属材料理化分析。</p>
            </li>
            <li>
              <div class="scopepic"><img src="../assets/images/s2.png" alt="" /></div>
              <h6>设备制造</h6>
              <p>
                通用设备及专用设备制造，精密机械零件、技术构件、铝制品、金属压力容器、有色铸造、金属表面处理及热处理、木制品包装的制造。</p>
            </li>
            <li>
              <div class="scopepic"><img src="../assets/images/s3.png" alt="" /></div>
              <h6>产品销售</h6>
              <p>
                通用设备及专用设备销售，精密机械零件、技术构件、铝制品、金属压力容器、有色铸造、金属表面处理及热处理、木制品包装的销售。</p>
            </li>
            <li>
              <div class="scopepic"><img src="../assets/images/s4.png" alt="" /></div>
              <h6>服务咨询</h6>
              <p>通用设备及专用设备服务，机电领域内的技术咨询、服务，质检技术服务。</p>
            </li>
            <li>
              <div class="scopepic"><img src="../assets/images/s5.png" alt="" /></div>
              <h6>维修改造</h6>
              <p>机器设备检测、维修、改造。</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import {useRouter} from 'vue-router'
import moment from "moment";
moment.locale("zh-cn");
const newlist = ref([]);
onMounted(async () => {
  var res = await axios.get("/webapi/news/list");
  console.log(res.data)
  newlist.value = res.data.data;
  var res1 = await axios.get("/webapi/manufacturing/list");
  manufacturinglist.value = res1.data.data;

  //console.log(_.groupBy(newlist.value,item=>item.category))
});
const topNewsList = computed(() => newlist.value.slice(0, 4));
const topNewsListTitle = computed(() => newlist.value.slice(0, 3));
const router = useRouter()
const manufacturinglist = ref([]);
const handleChangepage = (id)=>{
     //console.log(id)

    router.push(`/news/${id}`)
}
const whichTime = time => {
  return moment(time).format("lll");
};
const newsgateToTargetPage=()=>{
  router.push(`/news`)
}
const capabilitygateToTargetPage=()=>{
  router.push(`/capabilitys`)
}
const aboutgateToTargetPage=()=>{
  router.push(`/companyprofile`)
}
const corporateculturegateToTargetPage=()=>{
  router.push(`/corporateculture`)
}
const qualificationgateToTargetPage=()=>{
  router.push(`/qualification`)
}
const honorsgateToTargetPage=()=>{
  router.push(`/honors`)
}
const tablist =[
    {
        label:"铸造生产",
        name:1
    },
    {
        label:"先进钣金集成生产",
        name:2
    },
    {
        label:"大型框架件焊接制造",
        name:3
    },
    {
        label:"精密机加",
        name:4
    },
    {
        label:"热处理加工",
        name:5
    },
    {
        label:"表面处理、涂装",
        name:6
    },
    {
        label:"高精度检测",
        name:7
    }
  
]
const tabmanufacturing = computed(() => _.groupBy(manufacturinglist.value, item => item.category));
const handleChangepage1 = (name)=>{
     console.log(name)

    //router.push(`/capabilitys/${id}`)
     // 打开新窗口
     //console.log(id)
    // window.open(`http://localhost:8080/#/capabilitys/${id}`)
}
</script>
<style scoped lang="scss">
.bannerpic div{
    width: 100%;
    height: 100%;
    background-size: cover;
}
//最新动态
.news{
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
}
.newstitle h3{
  text-align: center;
  font-family: "OPPO Sans";
  font-size: 30px;
  font-weight: 530;
  padding-top: 30px;
}
.sanse{
  height: 3px;
  width: 95px;
  margin: auto;
}
.newsitem{
  padding-top: 30px;

}
.el-carousel__item h3 {
  color: white;
  opacity: 0.75;
  line-height: 700px;
  margin: 0;
  margin-left: 8px;
  height: 100%;
  font-size: 21px;
}

  
.newspics div{
  max-width: 100%;
	max-height: 100%;
}
* {
  font-family: "OPPO Sans";
  }
.newsdetailtitle{
  font-size: 20px;
  color: #00428d;
}
.time {
  font-size: 6px;
  color: gray;
  
}
.more-blue{
  color: white;
  text-align: center;
  vertical-align:middle;
  margin-top: 10px;
}
.more-white{
  color: #00428d;
  text-align: center;
  vertical-align:middle;
  padding-top: 50px;
  
}
//制造能力
.capability{
  margin-top: 80px;
  height: 690px;
  background-image: url('~@/assets/images/Home-blue.png');
  background-size: cover
}
.capabilitytitle h3{
  text-align: center;
  font-size: 30px;
  font-weight:530;
  color: white;;
  padding-top: 60px;
}
.capabilitydetail h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.capabilitydetail{
  padding-top: 60px;
  margin-left: 150px;
  margin-right: 150px;
}
.capabilitydetail div {
    
    height:100%;
    background-size: cover;
}
// .manufacturing{
//   height:100%;
//   background-size: cover;
// }
//公司介绍
.about{
  margin-top: 20px;
  height: 600px;

}
.abouttext p{
  padding-top: 40px;
  line-height: 36px;
}
.introduction{
  margin-top: 5px;
  margin-left: 50px;
  background-color: #00428d;
  width: 240px;
  height: 170px;
  text-align: center;
  color: white;
}
.introduction2{
  margin-top: 5px;
  margin-left: 57px;
  background-color: #00428d;
  width: 240px;
  height: 170px;
  text-align: center;
  color: white;
}
.introductionpic{
  width: 50%;
 display: block;
  margin: 0 auto;
  
}
//从事范围
.scope {
  margin-left: 10%;
  margin-right:  10%;
  height: 480px;
}
.scopetitle h3{
  text-align: center;
  font-family: "OPPO Sans";
  font-size: 30px;
  font-weight: 530;
  padding-top: 10px;
}
.i_mc {
  width: 100%;
  margin: 0px auto;
  padding: 20px 0;
}

.i_mc ul {
  width: 100%;
}

.i_mc ul li {
  width: 223px;
  float: left;
  height: 190px;
  border: 1px solid #ddd;
  float: left;
  margin-right: 18px;
  margin-top: 60px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
}

.i_mc ul li h6 {
  width: 90%;
  margin: 0px auto;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  padding-top: 60px;
}

.i_mc ul li .scopepic {
  width: 110px;
  height: 110px;
  position: absolute;
  top: -55px;
  left: 60px;
  transition: 0.7s;
  -moz-transition: 0.7s;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
}

.i_mc ul li:hover .scopepic {
  -moz-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.i_mc ul li p {
  width: 90%;
  margin: 0px auto;
  color: #555;
  line-height: 150%;
  height: 80px;
  overflow: hidden;
  text-align: left;
  font-size:13px; 
  padding-top:10px
}
.i_mc ul{
  list-style-type: none;
}

</style>