<template>
    <el-row>
        <el-col :span="16" :offset="2">
            <div class="toptitle">
                <h2>
                    {{ currentNews.title }}

                </h2>
                <div class="time">
                    {{whichTime(currentNews.editTime)}}
                </div>
                <el-divider>
                    <el-icon><star-filled /></el-icon>
                </el-divider>
            </div>
            <div v-html="currentNews.content" class="newsdetails"></div>
            <div class="author">
                {{ currentNews.author }}
            </div>
            <div class="blank"></div>
            <el-backtop :visibility-height="100" />

        </el-col>
        <el-col :span="4" :offset="1.5" :pull="0.5">
            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <span style="font-size:16px;font-weight:bold">最近新闻</span>

                    </div>
                </template>
                <div
                    v-for="item in topNews"
                    :key="item._id"
                    class="text item"
                    style="padding:14px"
                    @click="handleChange(item._id)"
                >
                    <span>{{item.title}}</span>
                    <div class="bottom">
                        <time class="time">{{ whichTime(item.editTime) }}</time>

                    </div>
                </div>
            </el-card>

        </el-col>
    </el-row>

</template>
<script setup>
//watchEffect里面依赖的的值变化了钩子重新执行
import { onMounted, ref,watchEffect,onBeforeUnmount } from "vue";
import axios from "axios";
import { useRoute,useRouter } from "vue-router";
import moment from "moment";
import { StarFilled } from "@element-plus/icons-vue";


moment.locale("zh-cn");
const route = useRoute();
const router = useRouter();
const currentNews = ref({});
const topNews = ref([]);

const stop  = watchEffect(async () => {
//   console.log(111,route.params.id)
  if(!route.params.id) return 
  const res1 = await axios.get(`/webapi/news/list/${route.params.id}`);
  const res2 = await axios.get(`/webapi/news/toplist?limit=5`);
  //   console.log(res2.data.data)
  currentNews.value = res1.data.data[0];
  topNews.value = res2.data.data;
});
  
onBeforeUnmount(()=>{
    console.log(222,"onBeforeUnmount")
    stop()
})
const whichTime = time => {
  return moment(time).format("lll");
};
const handleChange = (id)=>{
    // console.log(id)
    router.push(`/news/${id}`)
}
</script>
<style scoped lang="scss">
.el-row{
    margin-top: 40px;
}
.time {
  font-size: 13px;
  color: gray;
}
.toptitle{
    text-align: center;
}
.newsdetails::v-deep {
    font-size:18px;
     text-indent:35px; 
     line-height:35px;

    img {
        width: 600px;
        display: block;
        margin: 0 auto;
    }

}
.author{
    float: right;
    padding-top: 15px;
    padding-right: 10%;
}
.blank{
    height: 100px;
}
</style>