<template>
    <div class="container">
        <div class="news-header" :style="{backgroundImage:`url(${require('@/assets/newsbg.jpg')})`}">

        </div>
        <div class="search">
            <el-popover placement="bottom" title="检索结果" width="50%" :visible="visible">
                <template #reference>
                    <el-input v-model="searchText" placeholder="请输入新闻关键字" :prefix-icon="Search" type="search"
                        size="large" @input="visible=true" @blur="visible=false" />
                </template>
                <div v-if="searchnewslist.length">
                    <div v-for="data in searchnewslist" :key="data._id" class="search-item"
                        @click="handleChangepage(data._id)">
                        {{data.title}}
                    </div>
                </div>
                <div v-else>
                    <el-empty description="暂无新闻" :image-size="50" />

                </div>
            </el-popover>

        </div>
        <!-- <div class="topnews">
            <el-row :gutter="20">
                <el-col :span="6" v-for="item in topNewsList" :key="item._id">
                    <el-card :body-style="{ padding: '0px'}" s hadow="hover" @click="handleChangepage(item._id)">
                        <div class="image" :style="{
                            backgroundImage:`url(http://localhost:3000${item.cover})`
                        }">

                        </div>
                        <div style="padding: 14px">
                            <span>{{item.title}}</span>
                            <div class="bottom">
                                <time class="time">{{ whichTime(item.editTime) }}</time>

                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div> -->
        <div class="newstaps">
            <el-tabs v-model="whichTab" class="demo-tabs" style="margin: 20px;">
                <el-tab-pane :key="item.name" v-for="item in tablist" :label="item.label" :name="item.name">
                    <el-row :gutter="20">
                        <el-row>
                            <el-col :span="20">
                                <div v-for="data in tabnews[item.name]" :key="data._id"
                                    style="padding: 10px;font-weight: 400;">
                                    <el-card :body-style="{ padding: '0px' }" shadow="hover"
                                        @click="handleChangepage(data._id)">
                                        <el-row :gutter="20">
                                            <el-col :span="6">
                                                <div class="tab-image" :style="{
            backgroundImage: `url(http://localhost:3000${data.cover})`
        }">

                                                </div>
                                            </el-col>
                                            <el-col :span="18">
                                                <div style="padding: 14px;float: left;">
                                                    <span>{{ data.title }}</span>
                                                    <div class="bottom">
                                                        <time class="tab-time">{{ whichTime(data.editTime) }}</time>

                                                    </div>
                                                    <el-text line-clamp="2" style="width: 100%;padding-top: 12px; ">
                                                        <span>{{ data.sketch }}</span>

                                                    </el-text>

                                                </div>
                                            </el-col>
                                        </el-row>


                                    </el-card>

                                </div>
                            </el-col>
                            <el-col :span="4">
                                <el-timeline>
                                    <el-timeline-item v-for="(data, index) in tabnews[item.name]" :key="index"
                                        :timestamp="whichTime(data.editTime)">
                                        {{ data.title }}
                                    </el-timeline-item>
                                </el-timeline>

                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-pagination v-model:current-page="currentPage" :page-size="10"
                                    layout="prev, pager, next" :total="newlist.length"
                                    @current-change="handleCurrentChange" class="pagination" style="margin-left: 35%;">
                                </el-pagination>
                            </el-col>
                            <el-col :span="24" style="height: 70px;">&nbsp;</el-col>

                        </el-row>

                    </el-row>

                </el-tab-pane>

            </el-tabs>

            <el-backtop :visibility-height="100" />




        </div>
    </div>
</template>
<script setup>
import { Search } from "@element-plus/icons-vue";
import { ref,reactive, onMounted, computed } from "vue";
import axios from "axios";
import moment from 'moment'
import _ from "lodash";
import {useRouter} from 'vue-router'
import Pagination from './Pagination.vue';
const searchText = ref("")
const newlist = ref([]);
const visible = ref(false)

const whichTab =ref(1)

moment.locale("zh-cn");

// 给json数据定义一个list
const contactList = reactive({
    list: [],
  });
// 当前页数
const currentPage= ref(1);
  // 当前页数变化时的回调函数
  const handleCurrentChange = (val) => {
    currentPage.value = val;
  };

onMounted(async () => {
  var res = await axios.get("/webapi/news/list");
  console.log(res.data)
  newlist.value = res.data.data;
  contactList.value = res.data.data;

  //console.log(_.groupBy(newlist.value,item=>item.category))
});
//查找关键字相关新闻
const searchnewslist = computed(
  () =>
    searchText.value
      ? newlist.value.filter(item => item.title.includes(searchText.value))
      : []
);
//最新动态
//const NewsList = computed(() => newlist.value.filter(item=>item.name==1));

const topNewsList = computed(() => newlist.value.slice(0, 4));
//格式化时间
const whichTime = time=>{
    return moment(time).format("lll")
}
const tablist =[
    {
        label:"最新动态",
        name:1
    },
    // {
    //     label:"典型案例",
    //     name:2
    // },
    // {
    //     label:"通知公告",
    //     name:3
    // }
  
]
const tabnews = computed(() =>{
    let start = (currentPage.value - 1) * 10;
    const end = start + 10;
    const res =_.groupBy(newlist.value.slice(start, end), item => item.category);
    //console.log(tabnews[item.name]);
    return res;

} );
const router = useRouter()
const handleChangepage = (id)=>{
     //console.log(id)

    router.push(`/news/${id}`)
}

</script>
<style scoped lang="scss">
.container{
    position: relative;
}
.news-header{
    width: 100%;
    height: 250px;
    background-size: cover;
}
.search{
    position: absolute;
    top: 150px;
    width: 100%;
    text-align: center;
    .el-input{
        width: 50%;
    }
}
.search-item {
  height: 50px;
  line-height: 50px;
  &:hover {
    background: whitesmoke;
    color: #00428d;
  }
}

.topnews {
  margin: 80px;
  .image{
      width: 100%;
      height: 200px;
      background-size: cover;
  }
  .time{
      font-size:13px;
      color:gray;
  }
  .el-card{
    height: 300px;
  }
}
.tab-image{
    width: 230px;
    height: 150px;
    background-size: cover;
    float: left;
}
.newstaps{
    padding-left: 7%;
    padding-right: 7%;
}
.tab-time{
    font-size:13px;
    color:gray;
}
* {
  font-family: "OPPO Sans";
  }
  //页码
  .pagination{
    margin-left: 35%;
    margin-top: 30px;
  }
</style>