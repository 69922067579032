<link rel="stylesheet" type="text/css" href="Assets/css/thems.css"></link>
<template>
  <div>
    <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/about-us.jpg')})` }">
    </div>
    <!-- <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                 <el-breadcrumb-item>promotion list</el-breadcrumb-item>
            <el-breadcrumb-item>promotion detail</el-breadcrumb-item> 
            </el-breadcrumb>
            <el-divider style="margin-top: 10px;"/>
        </div> -->
    <div>
      <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
          @select="handleSelect" :router="true">
          <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
          <el-menu-item disabled="true"></el-menu-item>
          <el-menu-item index="/companyprofile" class="menu">公司简介</el-menu-item>
          <el-menu-item index="/corporateculture" class="menu">企业文化</el-menu-item>
          <el-menu-item index="/qualification" class="menu1">企业资质</el-menu-item>
          <el-menu-item index="/honors" class="menu">公司荣誉</el-menu-item>
          <el-menu-item index="/videos" class="menu">视频中心</el-menu-item>

        </el-menu>
        <div class="Breadcrumbbround">
          <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
              <el-breadcrumb-item :to="{ path: '/' }">
                <el-icon style="color: #00428d;font-size: larger;">
                  <HomeFilled />
                </el-icon>
              </el-breadcrumb-item>
              <el-breadcrumb-item>关于我们</el-breadcrumb-item>
              <el-breadcrumb-item>企业资质</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

        </div>
        <div class="scd_r">
          <div class="r_title">
            企业资质<span>/<em>Enterprise Qualification</em></span>
          </div>
          <div>&nbsp;</div>
          <div class="demo-image__preview">

            <el-row>
              <div class="tagline-txt">
                <p>经过持续发展，公司已成为我国自动化物流系统及装备、烟草制丝成套设备、打叶复烤成套设备的配套零部件供应商，民用机场装备研发生产的优势企业，是我国国防科技工业和国产重大装备制造的骨干力量。</p>
                <p>公司牢固树立“打造精品、做强主业”的理念，秉承
                  “质量为本、精益求精、改进提升、追求卓越”的质量方针。通过了ISO9001/GB/T9001B质量管理体系认证；具备二级保密资格、取得了D1/D2级压力容器生产许可、停车设备制造许可和三级计量检测资质证的认定，A级起重设备生产许可，获得了云南省排污许可证和辐射安全许可证。
                </p>
              </div>
            </el-row>
            <el-row style="height: 60px;">&nbsp;</el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 60%" :src="require('../assets/honor/知识产权.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList1" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 60%" :src="require('../assets/honor/能源管理认证.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList2" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 60%" :src="require('../assets/honor/环境质量管理体系认证证书_00.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList3" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 60%" :src="require('../assets/honor/职业健康安全管理休系认证证书_00.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList4" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 60%" :src="require('../assets/honor/质量管理体系认证证书_00.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList5" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 60%" :src="require('../assets/honor/辐射安全许可证.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList6" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 60%" :src="require('../assets/honor/特种设备型式试验证书.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList7" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 60%" :src="require('../assets/honor/特种设备型式试验证书2.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList8" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 60%" :src="require('../assets/honor/特种装备生产许可证.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList9" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 60%" :src="require('../assets/honor/特种装备生产许可证2.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList10" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="1"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/道路运输许可证.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList11" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/排污许可证.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList12" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>&nbsp;</el-row>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { ArrowRight,Calendar,HomeFilled } from '@element-plus/icons-vue';
import {useRouter} from 'vue-router'
const router = useRouter()
const newsgateToCulture=()=>{
  router.push(`/corporateculture`)
}
const newsgateToProfile=()=>{
  router.push(`/companyprofile`)
}
const newsgateToHonors=()=>{
  router.push(`/Honors`)
}
const newsgateToVideos=()=>{
  router.push(`/videos`)
}
const srcList1 = [
  require('../assets/honor/知识产权.jpg'),
];
const srcList2 = [
  require('../assets/honor/能源管理认证.jpg'),
]
const srcList3 = [
  require('../assets/honor/环境质量管理体系认证证书_00.jpg'),
]
const srcList4 = [
  require('../assets/honor/职业健康安全管理休系认证证书_00.jpg'),
]
const srcList5 = [
  require('../assets/honor/质量管理体系认证证书_00.jpg'),
]
const srcList6 = [
  require('../assets/honor/辐射安全许可证.jpg'),
]
const srcList7 = [
  require('../assets/honor/特种设备型式试验证书.jpg'),
]
const srcList8 = [
  require('../assets/honor/特种设备型式试验证书2.jpg'),
]
const srcList9 = [
  require('../assets/honor/特种装备生产许可证.jpg'),
]
const srcList10 = [
  require('../assets/honor/特种装备生产许可证2.jpg'),
]
const srcList11 = [
  require('../assets/honor/道路运输许可证.jpg'),
]
const srcList12 = [
  require('../assets/honor/排污许可证.jpg'),
]
</script>


<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
}
.menu{
  width: 190px;
  
  
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 17%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
//
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}
.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}
.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}
::v-deep .el-tabs__item {
    color:green;
    opacity: 0.5;
}
.news-header {
    width: 100%;
    height: 300px;
    background-size: cover;
}

* {
  font-family: "OPPO Sans";
  }
  .company{
    width: 80%;  
  }
  
  .company img{
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%,0%);
}

//
.company-pic{   
    width: 100%;
    padding-left: 40%;
}
.scd_r {
    width: 75%;
    margin: 0 auto;
}

.scd_r .r_title {
    width: 100%;
    text-align: center;
    padding-top: 55px;
    height: 45px;
    line-height: 45px;
    
    font-size: 30px;
    color: #000000;
}

.scd_r .r_title span {
    color: #999999;
}

.scd_r .r_title span em {
    font-size: 18px;
}

.scd_r .scd_m {
    width: 100%;
    padding: 28px 0;
    font-size: 14px;
    color: #999999;
    line-height: 215%;
}

.scd {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 20px;
}
.scd .scd_l{
	width: 220px;
	height: 700px;
	float: left;
	background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name{ width:100%; text-align:center; padding:30px 0;}
.scd_l .s_nav{
	width: 100%;
	text-align: left;
}
.scd_l .s_nav li{
	width: 190px;
	height: 42px;
	background: url(../assets/picture/line1.jpg) no-repeat left bottom;
	float: right;
	margin-right: 1px;
}
.scd_l .s_nav li a{
	display: block;
	width: 190px;
	height: 40px;
	padding-right: 0px;
	text-align: left;
	line-height: 40px;
	font-size: 16px;
	color: #552F00;
}
.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
    background: #00428d;
    color: #FFF;
}

.scd_l .s_nav li a span {
    display: inline-block;
    *display: inline;
    zoom: 1;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
    color: #FFF;
    background: url(../assets/picture/icon2.png) no-repeat left center;
}
.p{
    text-indent: 2em;
}
.tagline-txt p{
    font-size: 15px;
    text-indent: 2em;
}
</style>