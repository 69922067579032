<link rel="stylesheet" type="text/css" href="Assets/css/thems.css">
</link>
<template>
  <div>
    <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/Recruitment.jpg')})` }">
    </div>
    <div>
      <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
          @select="handleSelect" :router="true">
          <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
          <el-menu-item disabled="true"></el-menu-item>
          <el-menu-item index="/recruitment" class="menu1">招聘公告</el-menu-item>
          <el-menu-item index="/recruitmentschool" class="menu">校园招聘</el-menu-item>
          <el-menu-item index="/recruitmentsocial" class="menu">社会招聘</el-menu-item>

        </el-menu>
        <div class="Breadcrumbbround">
                <div class="Breadcrumb">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>诚聘英才</el-breadcrumb-item>
                        <el-breadcrumb-item>招聘公告</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

            </div>

        <div class="scd_r">
          <!-- <div class="r_title">
            公司简介<span>/<em>Company profile</em></span>
          </div> -->
          <div class="scd_m">
            <div class="recruitment">
              <h>敬请期待......</h>
              <!-- <h4>薪资福利</h4>
              <p>（一）薪酬</p>
              <p>
                云南昆船机械制造有限公司确立以价值创造者为本的指导思想，积极建立与现代企业制度相适应的薪酬制度，薪酬待遇包含基本工资、各项补贴（全勤奖、知识分子补贴、工龄补贴、交通补贴等）、过节费、福利费、绩效收入及缴纳五险两金等，对新进大学生给予较高薪酬待遇水平。
              </p>
              <p>
                新参加工作两年内，根据学历层次，给予较为稳定的年度薪酬收入水平为：博士研究生12万～15万，硕士研究生8万～12万，本科毕业生6万～10万。两年期满后，根据人事岗位、业务能力等综合评价核定。
              </p>
              <p>
                （二）福利
              </p>
              <p>
                1. 按规定缴纳五险两金；
              </p>
              <p>
                2. 双休、国家法定节假日、带薪年休假、婚嫁、产假、探亲假；
              </p>
              <p>
                3. 内外培训、交通补贴、健康体检等。
              </p>
              <p>
                4.安家费：985院校：博士30万、硕士20万、本科15万；
              </p>
              <p style="padding-left: 80px;">
                211院校：博士20万、硕士15万、本科10万。
              </p>
              <h4>发展晋升</h4>
              <p>
                1.公司非常重视人才，一直坚持“以人为本”的用人理念，用人机制完善，发展晋升通道畅通。
              </p>
              <p>
                2.公司三支人才队伍均设置有初职、中职、高职、公司级带头人、公司级首席专家、昆船级带头人、昆船级首席专家、中船级带头人、中船级首席专家的晋升通道。
              </p>
              <h4>招聘流程</h4>
              <p>
                简历收集及筛选→面试→签约
              </p>
              <p>
                个人简历:包括本人基本情况、教育背景、学业成绩、实习或工作经历等情况。
              </p>
              <p>
                各类证书原件及复印件。
              </p>
              <h4>联系方式</h4>
              <p>
                电话：15912456175（盛老师）、15974849356（杨老师）
              </p>
              <p>
                邮箱:kcjx_hr1@163.com (发送“姓名+学历+专业+应聘岗位”及简历)
              </p>
              <p>
                公司地址：云南省昆明市国家经济技术开发区昆船工业区
              </p> -->



              <p>&nbsp;</p>
              <!--<table width="160" border="0">
                <tr>
                  <img src="Assets/images/zz.jpg" width="150"  >
                  </tr>
                </table>
              <p>&nbsp;</p>
              <p><img src="Assets/images/企业架构图.png" width="1000" ></p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { ArrowRight, Calendar, HomeFilled } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router'
const router = useRouter()

</script>

<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
}
.menu{
  width: 190px;
  
  
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 30%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
//
.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}

.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}

.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}

::v-deep .el-tabs__item {
  color: green;
  opacity: 0.5;
}

.news-header {
  width: 100%;
  height: 300px;
  background-size: cover;
}

* {
  font-family: "OPPO Sans";
}

.company {
  width: 80%;
}

.company img {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
}

//
.company-pic {
  width: 100%;
  padding-left: 40%;
}

.scd_r {
  width: 75%;
  margin: 0 auto;
}

.scd_r .r_title {
  width: 100%;
  text-align: center;
  padding-top: 55px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
  font-size: 30px;
  color: #000000;
}

.scd_r .r_title span {
  color: #999999;
}

.scd_r .r_title span em {
  font-size: 18px;
}

.scd_r .scd_m {
  width: 100%;
  padding: 28px 0;
  font-size: 16px;
  color: #606060;
  line-height: 255%;
}

.scd {
  width: 1200px;
  margin: 0px auto;
  padding-bottom: 20px;
}

.scd .scd_l {
  width: 220px;
  height: 700px;
  float: left;
  background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name {
  width: 100%;
  text-align: center;
  padding: 30px 0;
}

.scd_l .s_nav {
  width: 100%;
  text-align: left;
}

.scd_l .s_nav li {
  width: 190px;
  height: 42px;
  background: url(../assets/picture/line1.jpg) no-repeat left bottom;
  float: right;
  margin-right: 1px;
}

.scd_l .s_nav li a {
  display: block;
  width: 190px;
  height: 40px;
  padding-right: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 16px;
  color: #552F00;
}

.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
  background: #00428d;
  color: #FFF;
}

.scd_l .s_nav li a span {
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
  color: #FFF;
  background: url(../assets/picture/icon2.png) no-repeat left center;
}

.scd_m p {
  text-indent: 2em;

}

.recruitment h4{
  color: #00428d;
  padding-top: 20px;
}
</style>