<template>
    <div class="Footer">
        <el-row style="margin-top: 25px;">
            <el-col :span="3">

            </el-col>
            <el-col :span="7">
                <el-text class="footertest">版权所有©云南昆船机械制造有限公司 <br />
                    地址：云南自由贸易试验区昆明经济技术开发区昆船工业园内 <br />
                    咨询电话：0871-67232089 <br />
                    假冒国企举报电话：0871-67232218 <br />
                    滇ICP备2020010221号 <br />
                </el-text>
            </el-col>
            <el-col :span="1">

            </el-col>
            <el-col :span="8">
                <img style="width: 80%; opacity:0.5;" src="/image/footerlogo.png" alt="Element logo" />
                <el-row>
                    <el-col :span="3">
                        <el-link :underline="false" href="">联系我们</el-link>
                    </el-col>
                    <el-col :span="1" style="margin-left: 10px;color: #dcdcdc;">
                        |
                    </el-col>
                    <el-col :span="3">
                        <el-link :underline="false" href="">网站导航</el-link>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="3" style="padding-top: 15px;font-size: small;color: #dcdcdc;opacity:0.8;" >
                        友情链接:
                   </el-col>
                   <el-col :span="21" >
                    <el-link :underline="false" href="" style="padding-top: 15px">昆明船舶设备集团有限公司</el-link>
                   </el-col>
                </el-row>
            </el-col>
            <el-col :span="4">
                <img style="width: 45%; opacity:0.9;" src="/image/code.jpg" alt="Element logo" />
                <div class="weixin">公司微信公众号</div>
            </el-col>
            <el-col :span="1">

            </el-col>
        </el-row>
    </div>
</template>
<script setup>

</script>
<style scoped lang="scss">
.Footer{
    display:flex; 
    flex-direction:column; 
    width: 100%;
    height: 200px;
    background-color: #00428d;

}
.footertest{
    color: #dcdcdc;
    font-size: small;
}
.el-link{
    color: #dcdcdc;
    font-size: small;
    opacity:0.8;
}
.weixin{
    color: #dcdcdc;
    font-size: small;
    opacity:0.8;
    margin-left: 5%;
}
</style>